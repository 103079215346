import React, { useContext, useEffect, useRef, useState } from 'react'
import config from '../../../config';
import { auth } from '../../../Context/AuthStore';
import Swal from 'sweetalert2';
import axios from 'axios';
import Validator from '../../../Validator';
import { useNavigate } from 'react-router-dom';
import Answers from './Answers';

export default function Questions({ data, refresh, examId, type }) {

    const navigate = useNavigate()

    const checkbox = useRef()

    const { userAuth } = useContext(auth)
    const [openCollaps, setOpenCollaps] = useState(false)
    const [newAnswers, setNewAnswers] = useState({ correct: false })
    const [openAddNewAnswer, setopenAddNewAnswer] = useState(false)
    const [isLoading, setisLoading] = useState(false)


    var refdiv = useRef(null);
    var rte = useRef(null);

    const openCollapsFunc = () => {
        openCollaps ? setOpenCollaps(false) : setOpenCollaps(true)
    }

    const togglePopup = () => {
        openAddNewAnswer ? setopenAddNewAnswer(false) : setopenAddNewAnswer(true)
        rte.current.setHTMLCode("");
        checkbox.current.checked = false
        setNewAnswers({ correct: false })
    }

    const closePopup = (e) => {
        if (e.target.classList.contains('add-new-popup')) {
            setopenAddNewAnswer(false)
        }
    }

    const addNewAnswer = (e) => {
        e.preventDefault()
        setisLoading(true)
        // copy form new answer data
        let dataNewAnswer = { ...newAnswers }
        dataNewAnswer['text'] = rte.current.getHTMLCode()


        // check if question have correct answer alredy

        let haveCorrectAnswer = data.answer_set.filter(answer => answer.correct === true)
        if (dataNewAnswer.correct == true) {
            if (haveCorrectAnswer.length > 0) {
                Swal.fire({
                    text: 'لا يمكن اضافه اكثر من اجابه صحيحه',
                    icon: 'warning'
                })
                setisLoading(false)
                togglePopup()
                return;
            }
        }


        // set answer number
        let answerNo = `answer-${data.answer_set.length + 1}`
        let ans = {
            answers_set: {}
        }

        ans.answers_set[answerNo] = dataNewAnswer;

        // validation phase
        let valid = new Validator;
        valid.setKey('text').setValue(dataNewAnswer.text).required()
        if (valid.haveError) {
            setisLoading(false)
            togglePopup()
            Swal.fire({
                text: 'جميع الحقول مطلوبه',
                icon: 'warning'
            })
            return;
        }

        axios.post(`${config.basURL}dashboard/${type}-answer/${data.id}`, ans, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`,
            }
        }).then(res => {
            setisLoading(false)
            refresh()
            togglePopup()
            Swal.fire({
                text: 'تم الاضافه بنجاح',
                icon: 'success'
            })
        }).catch(err => {
            setisLoading(false)
            console.log(err)
            togglePopup()
        })

    }

    const updateNewAnswers = (e) => {
        let data = { ...newAnswers }
        data.correct ? data.correct = false : data.correct = true;
        setNewAnswers(data)
    }

    const deleteLesson = (id) => {
        Swal.fire({
            title: `هل انت متاكد من حذف السؤال : ${data.text}`,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'لا شكرا',
            showConfirmButton: true,
            confirmButtonText: 'حذف',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                axios.delete(`${config.basURL}dashboard/${type}-questions/${examId}`, {
                    headers: {
                        'Authorization': config.ApiKey,
                        'auth': `Bearer ${userAuth}`,
                    },
                    data: { id_question: id }
                }).then(res => {
                    refresh()
                    Swal.fire({
                        text: 'تم الحذف بنجاح',
                        icon: 'success'
                    })
                })
            }
        })
    }

    const deleteAnswer = (id) => {
        Swal.fire({
            title: `هل انت متاكد من حذف الاجابه `,
            icon: "warning",
            showCancelButton: true,
            cancelButtonText: 'لا شكرا',
            showConfirmButton: true,
            confirmButtonText: 'حذف',
        }).then((willDelete) => {
            if (willDelete.isConfirmed) {
                axios.delete(`${config.basURL}dashboard/${type}-answer/${data.id}`, {
                    headers: {
                        'Authorization': config.ApiKey,
                        'auth': `Bearer ${userAuth}`,
                    },
                    data: { id_answer: id }
                }).then(res => {
                    refresh()
                    Swal.fire({
                        text: 'تم الحذف بنجاح',
                        icon: 'success'
                    })
                })
            }
        })
    }

    useEffect(() => {
        rte.current = new window.RichTextEditor(refdiv.current);
        rte.current.setHTMLCode("");
    }, [])

    return <>
        <div className="question-row">
            <div className="right">
                <p dangerouslySetInnerHTML={{ '__html': data?.text }} />
                <p className='question-grade'> درجه السؤال : {data.grade} </p>
                <p className='question-answers'> عدد الاجابات : {data.answer_count} </p>
            </div>
            <div className="left">
                <button className='answers' onClick={openCollapsFunc}> الاجابات </button>
                <button className='setting' onClick={() => navigate(`/dashboard/exam-question-update/${data.id}/${examId}/${type}`)}>
                    <i className="fa-solid fa-gear"></i>
                </button>
                <button className='delete' onClick={() => deleteLesson(data.id)}>
                    <i className="fa-regular fa-trash-can"></i>
                </button>
            </div>
        </div>
        {/* answers */}
        <div className={`answers-collaps ${openCollaps ? 'open' : ''}`}>
            <div className="collaps-header">
                <p> اجابات السؤال </p>
                <button onClick={togglePopup}> اضافه اجابه </button>
            </div>
            <div className="answers"> 
                {
                    data.answer_set.length > 0 ? data.answer_set.map(answer => <Answers allAnswers={data.answer_set} data={answer} deleteAnswer={deleteAnswer} refresh={refresh} type={type} data_id={data.id} totalAnswers={data.answer_set.length} />) : <p className='text-center w-100 empty'> لا توجد اجابات لهذا السؤال </p>
                }
            </div>
        </div>

        {/* add new answer popup */}
        <div className={`add-new-popup ${openAddNewAnswer ? 'open' : ''}`} onClick={closePopup}>
            <form className='new-box' onSubmit={addNewAnswer}>
                <div ref={refdiv} className='text'></div>
                <div className='check'>
                    <input type="checkbox" name="correct" id="correct-answer" value={true} onChange={updateNewAnswers} ref={checkbox} />
                    <label htmlFor="correct-answer"> الاجابه الصحيحه </label>
                </div>
                <button onClick={addNewAnswer} disabled={isLoading} className={isLoading && 'disabled'}>  {isLoading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'اضافه'}   </button>
            </form>
        </div>
    </>
}
