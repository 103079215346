import axios from 'axios'
import React from 'react'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import config from "../../../config"
import { auth } from '../../../Context/AuthStore'
import Placeholder from '../../../Components/Used/Placeholder'
import Swal from 'sweetalert2'
import Validator from "../../../Validator"
import LessonViewsRow from '../../../Components/Dashboard/LessonViewsRow/LessonViewsRow'
import CSVMake from '../../../CSVMake'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useQueryParams from '../../../Hooks/useQueryParams'

export default function LessonViews() {

    const location = useLocation();
    const queryParams = useQueryParams();

    const params = useParams()
    const navigate = useNavigate()

    const { userAuth } = useContext(auth)
    const [lessonViews, setLessonViews] = useState(false)
    const [searchBy, setsearchBy] = useState({})
    const [searchValue, setsearchValue] = useState({})
    const [nextPage, setNextPage] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchResults, setsearchResults] = useState(false)
    const [viewsCount, setviewsCount] = useState(0)

    const [haveNext, sethaveNext] = useState(false)
    const [havePrev, sethavePrev] = useState(false)

    const updateSearchBy = (e) => {
        let data = { ...searchBy };
        data[e.target.name] = e.target.value;
        setsearchBy(data)
    }


    const gonextPage = () => {
        let all_queries = { ...queryParams, ...searchBy }
        all_queries['page'] = parseInt(queryParams.page) + 1 || 2;
        let query_keys = Object.keys(all_queries);
        let link = `/dashboard/lesson-views`;

        query_keys.forEach((query, index) => {
            if (index == 0) {
                link += `?${query}=${all_queries[query]}`
            } else {
                link += `&${query}=${all_queries[query]}`
            }
        });

        navigate(link)
    }
    const prevPage = () => {
        let all_queries = { ...queryParams, ...searchBy }
        all_queries['page'] = parseInt(queryParams.page) - 1;
        let query_keys = Object.keys(all_queries);
        let link = `/dashboard/lesson-views`;

        query_keys.forEach((query, index) => {
            if (index == 0) {
                link += `?${query}=${all_queries[query]}`
            } else {
                link += `&${query}=${all_queries[query]}`
            }
        });
        navigate(link)
    }


    const getAllViews = () => {
        setLessonViews(false)

        let link = `${config.basURL}dashboard/lesson-views-list`

        if (Object.keys(queryParams).length > 0) {
            let query_keys = Object.keys(queryParams)
            query_keys.forEach((key, index) => {
                if (index == 0) {
                    link += `?${key}=${queryParams[key]}`
                } else {
                    link += `&${key}=${queryParams[key]}`
                }
            });
        }

        axios.get(link, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            setviewsCount(res.data.count)
            setLessonViews(res.data.results)
            res.data.next ? sethaveNext(true) : sethaveNext(false)
            res.data.previous ? sethavePrev(true) : sethavePrev(false)
        }).catch(err => {
            console.log(err)
        })
    }

    const search = (e) => {
        e.preventDefault();
        let link = `/dashboard/lesson-views`;
        let all_queries = { ...queryParams, ...searchBy }
        all_queries.page = 1;
        let query_keys = Object.keys(all_queries);

        query_keys.forEach((query, index) => {
            if (index == 0) {
                link += `?${query}=${all_queries[query]}`
            } else {
                link += `&${query}=${all_queries[query]}`
            }
        });

        navigate(link)
    }

    const exportCsv = () => {
        let csv = new CSVMake;
        csv.setTableHeader({
            student: ['name', 'اسم الطالب'],
            lesson: ['name', 'اسم الدرس'],
            watch_counte: 'عدد المشاهدات',
            watch_time: 'مده المشاهده'
        }).setData(lessonViews, {
            student: 'name',
            lesson: 'name'
        }).exportFile(` مشاهدات الدروس `)
    }

    useEffect(() => {
        if (userAuth) {
            getAllViews()
        }
    }, [userAuth, params])

    return <>
        <div className="container-fluid lesson-views-page">
            <h3>
                المشاهدات : ( {viewsCount} )
            </h3>
            {
                lessonViews && <button className='export-file my-4' onClick={exportCsv}>
                    <i className="fa-regular fa-file-excel"></i>
                    انشاء ملف Excel
                </button>
            }
            <div className="leasson-views-search d-flex flex-column">
                <form onSubmit={search}>
                    <div className='search-input'>
                        <input type="text" name='student__user__username' placeholder='رقم هاتف الطالب او الكود ' onChange={updateSearchBy} />
                        <button>
                            بحث
                        </button>
                    </div>
                    <div className='filters w-100 d-flex align-items-center' >
                        <div>
                            <label htmlFor="lesson_name">اسم الدرس </label>
                            <input type="text" name='lesson__name' onChange={updateSearchBy} />
                        </div>
                        <div>
                            <label htmlFor="code"> طريقه التسجيل </label>
                            <select name="student__by_code" id="code" onChange={updateSearchBy}>
                                <option > اختار طريقه التسجيل  </option>
                                <option value="true"> سنتر </option>
                                <option value="false"> اونلاين </option>
                            </select>
                        </div>
                    </div>
                </form>

            </div>
            <table className="content">
                <thead>
                    <tr>
                        <th> اسم الدرس </th>
                        <th> اسم الطالب </th>
                        <th> رقم الهاتف</th>
                        <th> مكان تسجيل الطالب </th>
                        <th> السنه الدراسية </th>
                        <th> عدد المشاهدات </th>
                        <th> مده المشاهده </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        lessonViews ? (lessonViews.length > 0 ? lessonViews.map(view => <LessonViewsRow key={view.id} data={view} refresh={getAllViews} />) : <tr>
                            <td colSpan={7} className='text-center'> لا توجد مشاهدات  </td>
                        </tr>) : <Placeholder />
                    }
                </tbody>
            </table>
            <div className="pagination-btn">
                <button onClick={prevPage} className={`prev ${havePrev ? '' : 'disable'}`} disabled={havePrev ? false : true}> السابق </button>
                <button className={`next ${haveNext ? '' : 'disable'}`} disabled={haveNext ? false : true} onClick={gonextPage}> التالي </button>
            </div>
        </div>
    </>
}
