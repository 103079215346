import React, { useContext, useEffect, useRef, useState } from 'react'
import config from '../../../config';
import { auth } from '../../../Context/AuthStore';
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Validator from '../../../Validator';

export default function QuestionUpdate() {

    const { userAuth } = useContext(auth)

    const navigate = useNavigate()

    const { question_id, exam_id , type } = useParams()
    const [questionUpdate, setquestionUpdate] = useState({})
    const [questionData, setquestionData] = useState(false)
    const [isLoading, setisLoading] = useState(false)

    var refdivupdate = useRef(null);
    var rteupdate = useRef(null);

    const addUpdateData = (e) => {
        let data = { ...questionUpdate }
        data[e.target.name] = e.target.value
        setquestionUpdate(data)
    }

    const getQuestionsData = () => {
        axios.get(`${config.basURL}dashboard/get-qu-text/${question_id}`, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            console.log(res.data)
            setquestionData(res.data)
            rteupdate.current.setHTMLCode(res.data.text);
        }).catch(err => console.log(err))
    }

    const sendUpdateData = (e) => {
        e.preventDefault();
        setisLoading(true)

        let sendData = { ...questionUpdate }

        sendData.text = rteupdate.current.getHTMLCode()

        // check if data change or not 
        if (Object.keys(sendData).length === 0) {
            setisLoading(false)
            Swal.fire({
                text: 'لا يوجد اي تعديل',
                icon: 'warning'
            })
            return;
        }

        axios.put(`${config.basURL}dashboard/question-update/${question_id}`, sendData, {
            headers: {
                'Authorization': config.ApiKey,
                'auth': `Bearer ${userAuth}`
            }
        }).then(res => {
            getQuestionsData()
            setisLoading(false)
            Swal.fire({
                text: 'تم التعديل بنجاح',
                icon: 'success'
            })
        }).catch(err => {
            console.log(err)
            setisLoading(false)
            Swal.fire({
                text: 'حدث خطاء برجاء المحاةله مره اخري',
                icon: 'error'
            })
        })
    }


    useEffect(() => {
        getQuestionsData()
        rteupdate.current = new window.RichTextEditor(refdivupdate.current);
    }, [userAuth])

    return <>
        <div className="container-fluid dashboard-queastions-answers update-question" >
            <div className="section-header">
                <h1>
                    تعديل السؤال
                </h1>
                <button onClick={() => navigate(`/dashboard/questions-answers/${exam_id}/${type}`)}>
                    العوده للامتحان
                </button>
            </div>
            <form action="" className='add-new-question' onSubmit={sendUpdateData}>
                <div ref={refdivupdate} ></div>
                {
                    questionData && <>
                        <input type="text" name='grade' placeholder={` درجه السؤال : ${questionData && questionData.grade} `} onChange={addUpdateData} />
                    </>
                }
                <button className='add-question'>
                    {
                        isLoading ? <i className="fa-solid fa-circle-notch fa-spin"></i> : 'تعديل'
                    }
                </button>
            </form>
        </div>
    </>
}
